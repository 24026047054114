// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export var AUTOCOMPLETE_API_PREFIX = '/api/v1/editor/autocomplete/';
export var BANNERS_API = '/api/v1/banners/';
export var SAMPLE_API_PREFIX = '/notebook/api/sample/';
export var EXECUTE_API_PREFIX = '/api/v1/editor/execute/'; // Dups with api.ts
export var DOCUMENTS_API = '/desktop/api2/doc/';
export var DOCUMENTS_SEARCH_API = '/desktop/api2/docs/';
export var GET_HUE_CONFIG_API = '/desktop/api2/get_hue_config';
export var FETCH_CONFIG_API = '/api/v1/get_config/';
export var HDFS_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('/');
export var OFS_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('ofs://');
export var ADLS_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('adl:/');
export var ABFS_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('ABFS://');
export var GIT_API_PREFIX = '/desktop/api/vcs/contents/';
export var S3_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('s3a://');
export var GS_API_PREFIX = '/filebrowser/view=' + encodeURIComponent('gs://');
export var IMPALA_INVALIDATE_API = '/impala/api/invalidate';
export var CONFIG_SAVE_API = '/desktop/api/configurations/save/';
export var CONFIG_APPS_API = '/desktop/api/configurations';
export var SOLR_COLLECTIONS_API = '/indexer/api/indexes/list/';
export var SOLR_FIELDS_API = '/indexer/api/index/list/';
export var DASHBOARD_TERMS_API = '/dashboard/get_terms';
export var DASHBOARD_STATS_API = '/dashboard/get_stats';
export var FORMAT_SQL_API = '/notebook/api/format';
export var GIST_API = '/desktop/api2/gist/';
export var GET_SLACK_CHANNELS = '/desktop/slack/api/channels/get';
export var SEND_SLACK_MESSAGE = '/desktop/slack/api/message/send';
export var TOPO_URL = '/desktop/topo/';
export var SEARCH_API = '/desktop/api/search/entities';
export var INTERACTIVE_SEARCH_API = '/api/v1/metadata/search/entities_interactive';
export var CREATE_SESSION_API = '/api/v1/editor/create_session'; // Note: dup by api.ts
export var CLOSE_SESSION_API = '/api/v1/editor/close_session'; // Note: dup by api.ts
export var FETCH_RESULT_SIZE_API = '/notebook/api/fetch_result_size';
export var FETCH_RESULT_DATA_API = '/notebook/api/fetch_result_data';
export var GET_LOGS_API = '/api/v1/editor/get_logs';
export var CANCEL_STATEMENT_API = '/notebook/api/cancel_statement';
export var CLOSE_STATEMENT_API = '/notebook/api/close_statement';
export var CHECK_STATUS_API = '/api/v1/editor/check_status';
export var HBASE_API_PREFIX = '/hbase/api/';
export var SAVE_TO_FILE_API = '/filebrowser/save';
export var NAV_API = {
  ADD_TAGS: '/metadata/api/catalog/add_tags',
  DELETE_TAGS: '/metadata/api/catalog/delete_tags',
  FIND_ENTITY: '/metadata/api/catalog/find_entity',
  LIST_TAGS: '/metadata/api/catalog/list_tags',
  UPDATE_PROPERTIES: '/metadata/api/catalog/update_properties'
};